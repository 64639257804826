<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);overflow-y: auto;">

        <!-- <div v-for="(item, index) in list" :key="index"
            style="margin-left: 28px;margin-right: 28px; margin-top: 10px;opacity: 1;border-radius: 15px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);overflow: hidden;">
            <div style="margin: 20px 0 10px 20px;font-size: 15px;">
                设备名称：{{ item.device_name }}
            </div>
            <div style="margin: 20px 0 10px 20px;font-size: 15px;">
                设备地址：{{ item.device_addr }}
            </div>



        </div> -->

        <van-collapse v-model="activeName" accordion
            style="margin-left: 28px;margin-right: 28px; margin-top: 10px;opacity: 1;border-radius: 15px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);overflow: hidden;">
            <van-collapse-item :title=item.name :name='index' v-for="(item, index) in list" :key="index">

                <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
                    <div :class="{ bule: get_box_num == obj_index, black: get_box_num != obj_index }"
                        style="margin-top: 10px;margin-bottom: 10px;" v-for="(obj, obj_index) in item.list" :key="obj_index"
                        @click="get_box_on(obj, obj_index)">
                        <div style="margin-top: 12px;margin-bottom: 18px;">
                            {{ obj.cargoway_num }} {{ obj.local_show }}
                        </div>
                        <div style="margin-top: 12px;margin-bottom: 18px;">
                            {{ obj.use_show }}
                        </div>

                    </div>
                </div>
            </van-collapse-item>

            <van-collapse-item title='全部清箱' :name='length'>

                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <div style="margin-right: 15px;">
                        正反面箱门:
                    </div>
                    <div>
                        <van-radio-group v-model="local" direction="horizontal">
                            <van-radio name="0">正面</van-radio>
                            <van-radio name="1">反面</van-radio>
                        </van-radio-group>
                    </div>
                </div>

                <div style="display: flex;align-items: center;justify-content: space-between;margin: 15px 0;">
                    <div style="margin-right: 15px;">
                        是否重置占用状态:
                    </div>
                    <div>
                        <van-radio-group v-model="use" direction="horizontal">
                            <van-radio name="0">恢复</van-radio>
                            <van-radio name="1">不恢复</van-radio>
                        </van-radio-group>
                    </div>
                </div>

                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <div style="margin-right: 15px;">
                        箱格是否占用:
                    </div>
                    <div>
                        <van-radio-group v-model="use_status" direction="horizontal">
                            <van-radio name="1">使用中</van-radio>
                            <van-radio name="2">未使用</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div style="margin: 15px auto;text-align: center;">
                    <van-button type="primary" @click="wechat_clear()">全部清箱</van-button>
                </div>
                
            </van-collapse-item>

        </van-collapse>

        <!-- <div style="margin: 20px auto; width: 100px;">
            <van-button type="primary">全部清箱</van-button>
        </div> -->



    </div>
</template>

<style scoped>
.black {
    width: 100px;
    height: 77px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(32, 32, 32, 0.1);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    font-size: 16px;
    font-weight: 500;
    color: rgba(32, 32, 32, 0.6);
    text-align: center;
    overflow: hidden;

}

.bule {
    width: 100px;
    height: 77px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(50, 100, 237, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0 -3px 0 rgba(0, 0, 0, 0.2);
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    overflow: hidden;
}

/* 文本省略 */
.add {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
import { wechat_box_list, wechat_open_box,wechat_clear } from "@/api/api";
import { Dialog, Notify } from 'vant';
export default {
    name: 'Lattice',
    data() {
        return {
            device_id: '',
            list: [],
            activeName: '0',
            get_box_num: -1,
            length: '',


            local: '',//正反面箱门
            use: '',//是否重置占用状态
            use_status: '',//箱格是否占用
        }

    },
    mounted() {
        this.device_id = this.$route.query.device_id
        this.wechat_box_list()

    },
    methods: {

        // 10-公众号操作-设备列表
        wechat_box_list() {
            wechat_box_list(this.device_id).then(res => {
                if (res.data.code == 200) {
                    this.list = res.data.data
                    this.length = res.data.data.length
                }
                console.log(res);
            })
        },

        // 点击换箱子颜色
        get_box_on(item, index) {
            console.log(item);
            // this.box_type = item.value
            console.log(index);
            this.get_box_num = index

            Dialog.confirm({
                message: '是否重置占用状态',
                cancelButtonText: '否',
                confirmButtonText: '是',
                closeOnClickOverlay: "true"
            }).then(() => {
                // on close
                this.wechat_open_box(item, 0, this.device_id)
            }).catch(() => {
                // on cancel
                this.wechat_open_box(item, 1, this.device_id)
            });


        },

        // 10-公众号操作-指定开箱
        wechat_open_box(item, num) {
            wechat_open_box(item.box_id, item.local_show, num, this.device_id).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    Notify({ type: 'primary', message: '重置成功' });
                    this.wechat_box_list()
                }
            })
        },

        // 10-公众号操作-公众号清箱子
        wechat_clear(){
            if (this.local=='') {
                Notify({ type: 'danger', message: '请选择正反面箱门' });
                return
            }
            if (this.use=='') {
                Notify({ type: 'danger', message: '请选择是否重置占用状态' });
                return
            }
            if (this.use_status=='') {
                Notify({ type: 'danger', message: '请选择箱格是否占用' });
                return
            }
            
            wechat_clear(this.local,this.use,this.use_status,this.device_id).then(res=>{
                console.log(res);
                if (res.data.code==200) {
                    Notify({ type: 'primary', message: '重置成功' });
                }
            })
        },



        purse() {
            this.$router.push('/purse')
        },
        orders() {
            this.$router.push('/orders')
        },
    }

}


</script>

