<template>
    <div
        style="height:100vh;overflow: auto;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="margin: 8px 15px 11px 15px;width: 300px;height: 361px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);">
            <div style="display: flex;justify-content: center;margin-top: 10px;">
                <img style="width: 100px;height: 100px;" src="../img/51.png" alt="">
            </div>
            <div style="text-align: center;font-size: 15px;">
                U创校园
            </div>

            <div style="display: flex;align-items: center;justify-content: space-between;margin: 10px 15px;">
                <div style="font-size: 15px;color: #000;">
                    单号
                </div>
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.4);;">
                    {{ get_reback_info_obj.order_sn }}
                </div>
            </div>

            <div style="display: flex;align-items: center;justify-content: space-between;margin: 10px 15px;"
                v-if="get_reback_info_obj.pickup_code != ''">
                <div style="font-size: 15px;color: #000;">
                    开箱码
                </div>
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.4);;">
                    {{ get_reback_info_obj.pickup_code }}
                </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;margin: 10px 15px;">
                <div style="font-size: 15px;color: #000;">
                    消费金额
                </div>
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.4);;">
                    {{ get_reback_info_obj.money }}￥
                </div>
            </div>
            <!-- <div style="display: flex;align-items: center;justify-content: space-between;margin: 10px 15px;">
                <div style="font-size: 15px;color: #000;">
                    时间戳
                </div>
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.4);;">
                    {{ time }}￥
                </div>
            </div> -->


        </div>

        <!-- <a href={{get_reback_info_obj.back_home}}> -->
        <van-button type="primary" @click="create_order"
            style="width: 300px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.34);margin: 18px 31px 0 31px;font-size: 16px;font-weight: 500;color: rgba(255, 255, 255, 1);">
            返回商家
        </van-button>
        <!-- </a> -->


        <!-- <button type="primary" @click="create_order"
            style="width: 312px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.34);margin: 18px 31px 0 31px;font-size: 16px;font-weight: 500;color: rgba(255, 255, 255, 1);">
            返回商家
        </button> -->









    </div>
</template>

<style scoped>
.black {
    width: 100px;
    height: 77px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(32, 32, 32, 0.1);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    font-size: 16px;
    font-weight: 500;
    color: rgba(32, 32, 32, 0.6);
    text-align: center;
    overflow: hidden;
}

.bule {
    width: 100px;
    height: 77px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(50, 100, 237, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0 -3px 0 rgba(0, 0, 0, 0.2);
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    overflow: hidden;
}

/* 文本省略 */
.add {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.van-cell-group--inset {
    margin: 0;
}
</style>


<script >
import { get_reback_info } from "@/api/api";
// import { Notify, Dialog } from 'vant';
// import wx from 'weixin-js-sdk'
export default {
    name: 'Receipt',
    data() {
        return {
            sub_ch_id: '',
            out_trade_no: "",
            check_code: "",

            get_reback_info_obj: {},
            time:''


        }

    },
    mounted() {

        var app = window.location.href
        // var app = 'https://www.gg.com?sub_ch_id=1234567&out_trade_no=YLWX24091455426158&check_code=39f0876dd58e4eb61b66250f69d02705'
        if (app.includes('?')) {
            // var add='https://www.gg.com?sub_ch_id=1234567&out_trade_no=YLWX24091455426158&check_code=39f0876dd58e4eb61b66250f69d02705'
            var add = window.location.href
            var arr = add.split('?')[1].split('&')
            this.sub_ch_id = arr[0].split('=')[1]
            this.out_trade_no = arr[1].split('=')[1]
            this.check_code = arr[2].split('=')[1]
            console.log(this.sub_ch_id);
            console.log(this.out_trade_no);
            console.log(this.check_code);


        }
        this.get_reback_info_on()
        var mchData = { action: 'onIframeReady', displayStyle: 'SHOW_CUSTOM_PAGE',height:900 }
        const postData = JSON.stringify(mchData)
        parent.postMessage(postData, 'https://payapp.weixin.qq.com');

        this.time=Date.now()
    },
    methods: {
        get_reback_info_on() {
            // this.out_trade_no = 'YLWX24091455426158'
            get_reback_info(this.out_trade_no).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    this.get_reback_info_obj = res.data.data
                }
            })
        },

        create_order() {
            var mchData = { action: 'jumpOut', jumpOutUrl: this.get_reback_info_obj.back_home }
        const postData = JSON.stringify(mchData)
        parent.postMessage(postData, 'https://payapp.weixin.qq.com');
            // this.$router.replace('/home')
            // location.replace(this.get_reback_info_obj.back_home)
            // window.location.href='https://www.baidu.com/'
        }









    }

}


</script>

