<template>
	<div
		style="background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);height:100vh;overflow: hidden;">
		<div>

			<img style="width: 311px;height: 146px;opacity: 1;position: absolute;left: 60px;top: 70px;"
				src="../img/1.png" alt="">
			<div
				style="position: absolute; left: 27px;top: 110px;width:71px;height: 66px;opacity: 1;font-size: 24px;font-weight: 500;letter-spacing: 0;line-height: 33px;color: rgba(32, 32, 32, 1);text-align: left;vertical-align;">
				HELLO!
			</div>
		</div>

		<div
			style="left: 30px;top:216px;width: 192px;height: 31px;opacity: 1;display: flex;justify-content: flex-start;align-items: flex-start;position: absolute;justify-content: space-between;">

			<div v-for="(item, index) in wenzi" :key="index" @click="wenzi_on(index)"
				:class="{ bule: wenzi_index == index, black: wenzi_index != index }">{{ item }}</div>
			<!-- <div class="bule">验证码登录</div>
			<div class="black">密码登录</div> -->

		</div>
		<div style="margin-top: 271px;margin-left: 32px;">
			<div>

				<van-cell-group inset style="width: 312px;height: 50px;font-size: 15px;align-items: center;">
					<van-field style="height: 50px;font-size: 15px;align-items: center;" v-model="mobile" placeholder="手机号" />
				</van-cell-group>
			</div>

			<div style="margin-top: 24px;" v-if="wenzi_index == 0">

				<van-cell-group inset style="width: 312px;">
					<van-field style="height: 50px;font-size: 15px;align-items: center;" v-model="code" center clearable placeholder="验证码">
						<template #button>
							<van-button v-if="fal_time" size="small" type="primary" @click="send_code">发送验证码</van-button>
							<van-count-down v-else :time="time" format="ss 秒" @finish="finish" />
						</template>
					</van-field>
				</van-cell-group>
			</div>

			<div style="margin-top: 24px;" v-if="wenzi_index == 1">

				<van-cell-group inset style="width: 312px;">
					<van-field style="height: 50px;font-size: 15px;align-items: center;" v-model="password" placeholder="密码" type="password" />
				</van-cell-group>
			</div>

			<div style="display: flex;align-items: center;margin-top: 12px;">
				<van-checkbox v-model="checked" icon-size="20px" style="font-size: 20px;"
					@change="changeann"></van-checkbox>
				<div style="font-size: 12px;margin-left: 10px;" @click="privacy()">
					我已阅读 <span style="color: blue;">用户协议</span> 和 <span style="color: blue;">隐私协议</span>
				</div>
			</div>




		</div>

		<!-- <van-button v-if="wenzi_index == 0"
			style="width: 3.12rem;height: 0.48rem;border-radius:0.1rem;margin-left: 0.32rem;margin-top: 0.24rem;"
			type="primary" @click="login_code">
			立即登录
		</van-button>
		<van-button v-else
			style="width: 3.12rem;height: 0.48rem;border-radius:0.1rem;margin-left: 0.32rem;margin-top: 0.24rem;"
			type="primary" @click="login_password">
			立即登录
		</van-button> -->

		<button  v-if="wenzi_index == 0" @click="login_code" style="font-size: 16px;border: none; color: white;width: 312px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.34);margin-left: 32px;margin-top: 24px;">
			立即登录
		</button>
		<button v-else @click="login_password" style="font-size: 16px;border: none;color: white;width: 312px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.34);margin-left: 32px;margin-top: 24px;">
			立即登录
		</button>


		<div style="margin: 125px auto;display: flex;align-items: center;justify-content: center;">
			<div @click="register"
				style="font-size: 16px;font-weight: 400;letter-spacing: 0;line-height: 22px;color: rgba(50, 100, 237, 1);text-align: left;vertical-align: top;text-align: center;">
				去注册
			</div>
			<img style="width: 16px;height: 16px;" src="../img/17.png" alt="">


		</div>








	</div>
</template>

<style scoped>
.black {
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 25px;
	color: rgba(32, 32, 32, 1);
	text-align: left;
}

.bule {
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 25px;
	color: rgba(50, 100, 237, 1);
	text-align: left;
	vertical-align: top;
}

.van-cell-group--inset {
	margin: 0;
}
</style>

<script >
import { get_wechat_code, login_code, send_code, login_password } from "@/api/api";
// import axios from "axios";
import { Notify } from 'vant';
export default {
	name: 'Login',
	data() {
		return {
			value: '',
			checked: 0,
			wenzi: ['验证码登录', '密码登录'],
			wenzi_index: 0,

			ann: '',//用户协议

			time: 60000,//倒计时
			fal_time: true,//倒计时判断值

			mobile: '',//手机号
			code: '',//手机验证码
			password: '',//密码


		}

	},
	created() {
		// this.getOpenId()
	},
	mounted() {
		// var add_url = 'https://localhost:8080/?device_id=203616406-1'
		// localStorage.setItem('openid', 'orVUixEC5qCvUYC3_XMDdBt9bDu0')
		this.getOpenId()
		var add_url = window.location.href

		if (add_url != '') {
			if (add_url.indexOf("device_id") != -1) {
				var arr_url = add_url.split('device_id')[1]
				localStorage.setItem('device_id', arr_url)
				// alert(arr_url)
			}

		}



	},
	methods: {
		// 02登录-手机验证码登录
		login_code() {
			if (this.mobile == '') {
				Notify({ type: 'primary', message: '手机号没有填' });
				return
			}
			if (this.code == '') {
				Notify({ type: 'primary', message: '验证码没有填' });
				return
			}
			if (this.ann == '') {
				Notify({ type: 'primary', message: '用户协议没有勾选' });
				return
			}

			var data = {
				mobile: this.mobile,
				code: this.code
			}
			login_code(data).then(res => {
				if (res.data.code == 200) {
					console.log(res);
					localStorage.setItem('user_id', res.data.data.user_id)
					this.$router.push('/home')
				} else {
					Notify({ type: 'primary', message: res.data.msg });
				}
			});

		},

		// 02登录-手机号密码登录
		login_password() {
			if (this.mobile == '') {
				Notify({ type: 'primary', message: '手机号没有填' });
				return
			}
			if (this.password == '') {
				Notify({ type: 'primary', message: '密码没有填' });
				return
			}
			if (this.ann == '') {
				Notify({ type: 'primary', message: '用户协议没有勾选' });
				return
			}

			var data = {
				mobile: this.mobile,
				password: this.password
			}
			login_password(data).then(res => {
				if (res.data.code == 200) {
					console.log(res);
					console.log(res.data.data);
					localStorage.setItem('user_id', res.data.data.user_id)
					this.$router.push('/home')
				} else {
					Notify({ type: 'primary', message: res.data.msg });
				}
			});
		},

		// 02登录-获取手机验证码
		send_code() {
			if (this.mobile == '') {
				Notify({ type: 'primary', message: '手机号没有填' });
				return
			}
			send_code(this.mobile).then(res => {
				if (res.data.code == 200) {
					console.log(res);
					this.fal_time = false
				}
				console.log(res);
			});

		},
		// 倒计时结束
		finish() {
			this.fal_time = true
		},


		// 点击变色
		wenzi_on(index) {
			this.wenzi_index = index
		},

		// 用户协议
		changeann(e) {
			console.log(e);
			this.ann = e
		},




		getUrlParam(name) {
			var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
			let url = window.location.href.split('#')[0]
			let search = url.split('?')[1]
			if (search) {
				var r = search.substr(0).match(reg)
				if (r !== null)
					return unescape(r[2])
				return null
			} else
				return null
		},

		getOpenId() {
			const code = this.getUrlParam('code')  // 截取路径中的code,
			if (code == null || code === '' || code === false) {
				const local = 'http://shuangxiangguih5.selfbao.com/'
				let appid = 'wxc21c717d8211eced'
				window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect';


			} else {
				get_wechat_code(code).then(res => {
					if (res.data.code == 200) {
						// var openid = JSON.parse(res.data.data.openid)
						localStorage.setItem('openid', res.data.data.openid)
						// alert(res.data.data.openid)
					}


				})
			}

		},




		register() {
			this.$router.push('/register')
		},
		home() {
			this.$router.push('/home')
		},
		privacy() {
			this.$router.push('/privacy')
		},

	}

}


</script>
