<template>
    <div
        style="position: fixed;bottom: 0;left: 23.5px; overflow: hidden; width:234px;height:55px;padding: 10px 47px; opacity:1;border-radius:60px;background:rgba(255,255,255,1);box-shadow:0 3px 12px rgba(0,0,0,0.09);display: flex;justify-content: space-between;align-items: center;">


        <div @click="goTo_home">
            <img v-if="fal_home" style="width: 28px;height: 28px;" src="../img/Home1.png" alt="">
            <img v-else style="width: 28px;height: 28px;" src="../img/Home2.png" alt="">
        </div>
        <!-- <div>
            <img style="width: 0.55rem;height: 0.55rem;" src="../img/8.png" alt="">
        </div> -->
        <div @click="goTo_my">
            <img v-if="fal_my" style="width: 28px;height: 28px;" src="../img/Icon1.png" alt="">
            <img v-else style="width: 28px;height: 28px;" src="../img/Icon2.png" alt="">
        </div>



    </div>
</template>


<script >
export default {
    name: 'BottomNavigation',
    data() {
        return {
            value: '',
            fal_home:true,
            fal_my:false,
        }

    },
    mounted() {
        // this.add()
    },
    methods: {
        goTo_home() {
            this.$router.push('/home')
            console.log(111);
            this.fal_home=true
            this.fal_my=false
        },
        goTo_my() {
            this.fal_home=false
            this.fal_my=true
            this.$router.push('/my')
        }
    }

}


</script>










