<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="width: 345px;height: 209px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0px 3px 12px  rgba(0, 0, 0, 0.09);margin: 0 15px 0 15px;overflow: hidden;">

            <div style="display: flex;align-items: center;margin: 15px 15px 12px 15px;">
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.4);">
                    申请姓名：
                </div>
                <div style="font-size: 12px;color: rgba(50, 100, 237, 1);">
                    {{apply_data.nickname}}
                </div>
            </div>



            <div style="display: flex;align-items: center;margin: 15px 15px 12px 15px;">
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.4);">
                    备注：
                </div>
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.8);">
                    {{apply_data.remark}}
                </div>
            </div>

            <div style="display: flex;align-items: center;margin: 15px 15px 12px 15px;">
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.4);">
                    申请时间：
                </div>
                <div style="font-size: 12px;color: rgba(32, 32, 32, 0.8);">
                    {{apply_data.create_at}}
                </div>
            </div>

            <div
                style="width: 315px;height: 0px;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 15px 15px 8px 15px;">
            </div>

            <van-button type="default"
                style="font-size: 12px;color: rgba(32, 32, 32, 1);width: 98px;height: 36px;opacity: 1;border-radius: 10px;background: rgba(32, 32, 32, 0.1);margin-left: 15px;">
                联系客服
            </van-button>


        </div>

        <div style="margin: 20px 15px 0 15px;background: rgba(255, 255, 255, 1);box-shadow: 0px 3px 12px  rgba(0, 0, 0, 0.09);border-radius: 10px;">
            <van-steps direction="vertical" :active=apply_timezone_key>
                <van-step v-for="(item,index) in apply_timezone_record" :key="index" >
                    <h3>{{item.type}}</h3>
                    <p>{{item.create_at}}</p>
                </van-step>
                <!-- <van-step>
                    <h3>【城市】物流状态2</h3>
                    <p>2016-07-11 10:00</p>
                </van-step>
                <van-step>
                    <h3>快件已发货</h3>
                    <p>2016-07-10 09:30</p>
                </van-step> -->
            </van-steps>
        </div>


    </div>
</template>

<style scoped>
.black {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25px;
    color: rgba(32, 32, 32, 1);
    text-align: left;
}

.bule {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 25px;
    color: rgba(50, 100, 237, 1);
    text-align: left;
    vertical-align: top;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
import { refund_info } from "@/api/api";
export default {
    name: 'Progress',
    data() {
        return {
            value: '',
            user_id: '',
            device_id: '',
            refund_apply_id:'',


            apply_data: {},//用户信息
            apply_timezone_record: [],
            apply_timezone_key:'',
        }

    },
    mounted() {
        // this.add()
        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        // this.role_type = use.role_type
        this.user_id = use

        var azz = localStorage.getItem('device_id');
        azz = azz.split('=')[1]
        this.device_id = azz
        console.log(this.device_id);

        this.refund_apply_id=this.$route.query.refund_apply_id
        console.log(this.refund_apply_id);

        this.refund_info_on()

    },
    methods: {

        refund_info_on(){
            refund_info(this.device_id,this.user_id,this.refund_apply_id).then(res=>{
                if (res.data.code==200) {
                    this.apply_data=res.data.data.apply_data
                    this.apply_timezone_record=res.data.data.apply_timezone_record
                    this.apply_timezone_key=res.data.data.apply_timezone_key
                }
                console.log(res);
            })
        },


        // purse() {
        //     this.$router.push('/purse')
        // },
        // orders() {
        //     this.$router.push('/orders')
        // },
    }

}


</script>

