<template>
	<div style="height:100vh;overflow: hidden;">

		<img style="width: 375px;" src="../img/9.png" alt="">
		<img style="width: 346px;height: 230px;position: absolute;left: 15px;top: 50px;" src="../img/10.png" alt="">
		<div
			style="display: flex;align-items: center; width: 272px;height: 28px;opacity: 1;border-radius: 88px;background: rgba(14, 58, 179, 0.53);position: absolute;left: 15px;top: 18px;">
			<img style="width: 11px;height: 13px;margin: 0 13px 0 7.3px ;" src="../img/11.png" alt="">
			<div
				style="font-size: 14px;font-weight: 400;letter-spacing: 0;line-height: 16px;color: rgba(255, 255, 255, 1); overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;">
				{{ device_addr }}
			</div>
		</div>

		<div style="margin: 44.5px 14px 19px 14px;display: flex;justify-content: space-between;">
			<div @click="lockers"
				style="display: flex;align-items: center; width: 165px;height: 99px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 12px  rgba(0, 0, 0, 0.09);">
				<img style="width: 62px;height: 62px;" src="../img/12.png" alt="">
				<div style="font-size: 24px;font-weight: 700;color: rgba(32, 32, 32, 1);margin-left: 36px;">
					存件
				</div>

			</div>
			<div @click="pick_up"
				style="display: flex;align-items: center; width: 165px;height: 99px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 12px  rgba(0, 0, 0, 0.09);">
				<img style="width: 62px;height: 62px;" src="../img/13.png" alt="">
				<div style="font-size: 24px;font-weight: 700;color: rgba(32, 32, 32, 1);margin-left: 36px;">
					取件
				</div>

			</div>


		</div>

		<div
			style="overflow: hidden; width: 346px;height: 179px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 12px rgba(0, 0, 0, 0.09);margin: 0 15px;">
			<div style="margin: 24px 8px;">

				<van-field
					style="background: rgba(229, 229, 229, 1);color: rgba(32, 32, 32, 1);border-radius: 5px;height: 50px;font-size: 15px;align-items: center;"
					v-model="pickup_code" label="输入取件码" type="number" placeholder="请输入" />
				<!-- <input type="text" style="background: rgba(229, 229, 229, 1);color: rgba(32, 32, 32, 1);border-radius: 5px;height: 50px;font-size: 15px;align-items: center;"> -->

				<van-button type="primary" @click="pickup_code_on"
					style="width: 312px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.34);margin: 18px 9px 0 9px;font-size: 16px;font-weight: 500;color: rgba(255, 255, 255, 1);">
					开箱
				</van-button>


			</div>





		</div>


	</div>
</template>

<style scoped>
.black {
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 25px;
	color: rgba(32, 32, 32, 1);
	text-align: left;
}

.bule {
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 25px;
	color: rgba(50, 100, 237, 1);
	text-align: left;
	vertical-align: top;
}

.van-cell-group--inset {
	margin: 0;
}
</style>

<script >
import { pickup_code } from "@/api/api";
import { Notify, Dialog } from 'vant';
export default {
	name: 'Home',
	data() {
		return {
			value: '',
			device_id: '',
			device_addr: '山东科技大学青岛校区',
			pickup_code: '',
		}

	},
	mounted() {
		var app = window.location.href
		if (app.includes('?')) {
			// var app = window.location.href.split('?')[1]
			var yy = this.$route.query.device_id
			var uu = this.$route.query.user_id
			var ii = this.$route.query.openid
			localStorage.setItem('openid',ii)
			localStorage.setItem('user_id', uu)
			localStorage.setItem('device_id', '='+yy)
		}


		// this.add()
		var azz = localStorage.getItem('device_id');
		azz = azz.split('=')[1]
		this.device_id = azz
		console.log(this.device_id);

		// this.device_info()
	},
	methods: {
		pickup_code_on() {
			if (this.pickup_code == '') {
				Notify({ type: 'danger', message: '取件码不为空' });
				return
			}
			pickup_code(this.device_id, this.pickup_code).then(res => {
				if (res.data.code == 200) {
					// this.device_addr = res.data.data.device_addr
					Dialog.alert({
						message: res.data.data.row_col,
					}).then(() => {
						// on close
						this.pickup_code = ''
					});
				} else {
					Notify({ type: 'danger', message: res.data.msg });
				}
				console.log(res);
			});
		},
		lockers() {
			this.$router.push('/lockers')
		},
		pick_up() {
			this.$router.push('/pick_up')
		},
	}

}


</script>

