import axios from "axios";
// import { ElMessage } from "element-plus";
const CODE_MESSAGE = {
    200: '服务器成功返回请求数据',
    201: '新建或修改数据成功',
    202: '一个请求已经进入后台排队(异步任务)',
    204: '删除数据成功',
    313: '账号退出成功',
    400: '发出信息有误',
    401: '用户没有权限(令牌失效、用户名、密码错误、登录过期)',
    402: '令牌过期',
    403: '用户得到授权，但是访问是被禁止的',
    404: '访问资源不存在',
    406: '请求格式不可得',
    410: '请求资源被永久删除，且不会被看到',
    500: '服务器发生错误',
    502: '网关错误',  
    503: '服务不可用，服务器暂时过载或维护',
    504: '网关超时',
}  
// 创建 axios
const service = axios.create({
    // baseURL: '/api', 
    baseURL: 'https://shuangxiangguih5.selfbao.com/api/', 
    // baseURL: 'https://dmsmpapi.selfbao.com/sxkmg', 
    // baseURL: 'http://localhost:8001/API/Service1.svc',
    // baseURL: 'https://www.remotehealth.cn/dass/Service1.svc/',
    timeout: 10000, //请求超时时间 
    withCredentials: true, // 表示请求可以携带cookie
    headers: {
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        post: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    }
});


// 请求拦截器
service.interceptors.request.use((config) => {
    // frontTokenExpire(config);
    return config


}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    error.data.msg = '服务器异常，请联系管理员！'
    return Promise.resolve(error)
})
// 响应拦截器
service.interceptors.response.use((response) => {
    const status = response.status
    let msg = ''
    if (status < 200 || status >= 300) {
        // 处理http错误，抛到业务代码
        msg = CODE_MESSAGE[parseInt(status)]
        if (typeof response.data === 'string') {
            response.data = { msg }
        } else {
            // ElMessage.error(msg)
            response.data.msg = msg
        }
    }
    return response
}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    // error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'
    // error.data.msg =error.data.msg
    return Promise.resolve(error.data.msg)
})

export default service;