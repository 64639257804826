<template>
    <div
        style="height:100vh;overflow: auto;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div style="overflow-y:scroll;overflow-x:hidden;height:100vh">

            <!-- 订单详情 -->
            <!-- <div @click="order_details"
                style="overflow: hidden;margin: 0.05rem 0.15rem;width: 3.46rem;height: 1.61rem;opacity: 1;border-radius: 0.1rem;background: rgba(255, 255, 255, 1);box-shadow: 0rem 0.03rem 0.12rem  rgba(0, 0, 0, 0.09);">

                <div style="margin: 0.11rem 0.08rem;display: flex;justify-content: space-between;align-items: center;">
                    <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                        苏州大学附属中学(苏大附中)6号柜机
                    </div>
                    <div style="font-size: 0.16rem;font-weight: 400;color: rgba(32, 32, 32, 0.4);">
                        已取件
                    </div>

                </div>
                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <img style="width: 0.1rem;height: 0.13rem;" src="../img/14.png" alt="">
                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                        江苏省苏州市苏州工业园区东振路29号
                    </div>
                </div>

                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;justify-content: space-between;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <div style="display: flex;align-items: center;">
                        <img style="width: 0.1rem;height: 0.13rem;" src="../img/15.png" alt="">
                        <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                            储柜号码
                        </div>
                        <div style="font-size: 0.14rem;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 0.1rem;">
                            066820
                        </div>

                    </div>
                    <van-button type="default"
                        style="width: 0.92rem;height: 0.31rem;opacity: 1;border-radius: 0.1rem;background: rgba(32, 32, 32, 0.3);color: rgba(255, 255, 255, 1);">订单详情</van-button>



                </div>

                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <img style="width: 0.1rem;height: 0.13rem;" src="../img/16.png" alt="">
                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                        已支付
                    </div>
                    <div style="font-size: 0.14rem;font-weight: 500;color: rgba(236, 102, 75, 1);margin-left: 0.1rem;">
                        1.5元
                    </div>
                </div>
            </div> -->

            <!-- 去支付 -->

            <div style="margin-top: 200px;" v-if="order_list_data.length == 0">
                <div style="display: flex;align-items: center;justify-content: center;">
                    <img style="width: 202px;height: 108px;" src="../img/49.png" alt="">
                </div>

                <div
                    style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);text-align: center;margin-top: 20px;">
                    暂无订单
                </div>
            </div>


            <div v-for="(item, index) in order_list_data" :key="index"
                style="overflow: hidden;margin: 5px 15px;width: 346px;height: 161px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0px 3px 12px  rgba(0, 0, 0, 0.09);">

                <div style="margin: 11px 8px;display: flex;justify-content: space-between;align-items: center;">
                    <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);">
                        {{ item.device_addr }}
                    </div>
                    <div style="font-size: 16px;font-weight: 400;color: rgba(50, 100, 237, 1);">
                        {{ item.shipment_status == 0 ? '未取件' : item.shipment_status == 2 ? '已取件' : '' }}
                    </div>

                </div>
                <div
                    style="display: flex;align-items: center;margin-top: 17px;margin-left: 8px;margin-right: 8px;">
                    <img style="width: 10px;height: 13px;" src="../img/14.png" alt="">
                    <div style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 12px;">
                        {{ item.device_addr }}
                    </div>
                </div>

                <div
                    style="display: flex;align-items: center;margin-top: 17px;justify-content: space-between;margin-left: 8px;margin-right: 8px;">
                    <div style="display: flex;align-items: center;">
                        <img style="width: 10px;height: 13px;" src="../img/15.png" alt="">
                        <div style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 12px;">
                            储柜号码
                        </div>
                        <div style="font-size: 14px;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 10px;">
                            {{ item.row_col }}
                        </div>

                    </div>
                    <!-- <van-button type="primary" @click="get_box_info(item)"
                        style="width: 0.78rem;height: 0.31rem;opacity: 1;border-radius: 0.1rem;">取物品</van-button> -->

                    <van-button type="primary" v-if="item.need_pay_pick == 2" @click="get_box_info(item)"
                        style="width: 92px;height: 31px;opacity: 1;border-radius: 10px;">
                        取物品
                    </van-button>

                    <van-button type="primary" v-else @click="pay_pick_on(item.order_sn)"
                        style="width: 92px;height: 31px;opacity: 1;border-radius: 10px;">
                        取物品
                    </van-button>

                </div>

                <div v-if="item.need_pay_pick == 1"
                        style="display: flex;align-items: center;margin-top: 17px;margin-left: 8px;margin-right: 8px;">
                        <img style="width: 10px;height: 13px;" src="../img/16.png" alt="">
                        <div style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 12px;">
                            需支付
                        </div>
                        <div style="font-size: 14px;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 10px;">
                            {{ item.pay_amount_pick }}元
                        </div>
                    </div>
                <!-- <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <img style="width: 0.1rem;height: 0.13rem;" src="../img/16.png" alt="">
                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                        未支付
                    </div>
                    <div style="font-size: 0.14rem;font-weight: 500;color: rgba(236, 102, 75, 1);margin-left: 0.1rem;">
                        1.5元
                    </div>
                </div> -->

            </div>






        </div>








    </div>
</template>

<style scoped>
.black {
    width: 78px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(32, 32, 32, 0.6);
    line-height: 40px;
    text-align: center;
}

.bule {
    width: 78px;
    height: 40px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(50, 100, 237, 1);
    box-shadow: 0px 2px 9px rgba(50, 100, 237, 0.34);
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
import { get_box_info, all_order_list,pay_pick } from "@/api/api";
import { Dialog } from 'vant';
export default {
    name: 'PickUp',
    data() {
        return {
            value: '',
            user_id: '',//用户id
            device_id: '',//设备id
            openid: '',
            order_list_data: [],
            page: 1,
            limit: '10000',
        }

    },
    mounted() {
        var azz = localStorage.getItem('device_id');
        azz = azz.split('=')[1]
        this.device_id = azz
        console.log(this.device_id);

        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        this.user_id = use

        this.openid = localStorage.getItem('openid')

        this.all_order_list()
    },
    methods: {
        // 04订单-全部
        all_order_list() {

            var data = {
                device_id: this.device_id,
                user_id: this.user_id,
                page: this.page,
                limit: this.limit,
                type: 20
            }

            all_order_list(data).then(res => {
                if (res.data.code == 200) {
                    this.order_list_data = res.data.data.list

                }
                console.log(res);
            })
        },

        // 04-取件操作
        get_box_info(item) {

            get_box_info(item.order_id).then(res => {
                if (res.data.code == 200) {
                    Dialog.alert({
                        message: '开箱成功：' + item.row_col,
                    }).then(() => {
                        // on close
                        this.all_order_list()
                    });
                }
                console.log(res);
            })
        },


        // 03-支付-取件超时支付
        pay_pick_on(order_sn) {
            var data = {
                device_id: this.device_id,
                order_sn: order_sn,
                openid: this.openid
            }
            pay_pick(data).then(res => {
                if (res.data.code == 200) {
                    console.log(res);
                    this.pay_recharge_obj = res.data.data
                    this.callpay()
                }
                console.log(res);
            })
        },

        jsApiCall() {
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', this.pay_recharge_obj,
                (res) => {

                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        console.log(res.err_msg);
                        // this.$router.push('/recharge')
                    } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                        alert("用户取消支付!");
                    } else {
                        // alert(res.err_msg)
                        alert("支付失败!");
                    }
                }
            );
        },

        callpay() {
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.jsApiCall(), false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.jsApiCall());
                    document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall());
                }
            } else {
                this.jsApiCall();
            }
        },


        Certificates() {
            // this.$router.push('/certificates')
        },
        pick_up_detail() {
            // this.$router.push('/pick_up_detail')
        },
    }

}


</script>

