<template>
    <div
        style="height:100vh;overflow: auto;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="margin: 80px 15px 11px 15px;width: 346px;height: 361px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);">

            <div style="display: flex;align-items: center;justify-content: space-between;">
                <img style="width: 78px;height: 78px;border-radius: 50%;" :src="user_info_obj.avatar" alt="">
                <div>
                    <div style="font-size: 16px;font-weight: 700;color: rgba(56, 56, 56, 1);">
                        {{ user_info_obj.nickname }}
                    </div>
                    <div style="display: flex;align-items: center;">
                        <!-- <img style="width: 0.46rem;height: 0.33rem;" src="../img/19.png" alt=""> -->
                        <div style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                            免费次数 <span style="font-size: 12px;color: rgba(50, 100, 237, 1);">0次</span>
                        </div>
                    </div>

                </div>

                <div style="display: flex;align-items: center;">
                    <img style="width: 13px;height: 15px;" src="../img/44.png" alt="">
                    <img style="width: 15px;height: 15px;margin: 0 8px 0 18px;" src="../img/45.png" alt="">
                </div>
            </div>

            <!-- <div style="display: flex;align-items: center;margin-top: 0.21rem;">
                <img style="width: 0.11rem;height: 0.13rem;margin-left: 0.13rem;" src="../img/42.png" alt="">
                <div class="add"
                    style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);margin-left: 0.1rem;">
                    苏州大学附属中学(苏大附中)6号柜机
                </div>
            </div> -->

            <div
                style="margin: 20px 13px 29px 13px;display: flex;justify-content: space-between;align-items: center;">
                <div :class="{ bule: get_box_num == index, black: get_box_num != index }"
                    v-for="(item, index) in get_box_list" :key="index" @click="get_box_on(item, index)">
                    <div style="margin-top: 12px;margin-bottom: 18px;">
                        {{ item.name }}
                    </div>
                    <div v-if="get_box_num == index && get_accessible_box_obj != ''">
                        {{ get_accessible_box_obj.price }}元/次
                    </div>
                </div>
                <!-- <div class="black">
                    <div style="margin-top: 0.12rem;margin-bottom: 0.18rem;">
                        中柜
                    </div>
                    <div>
                        0.3元/次
                    </div>
                </div> -->
            </div>

            <div
                style="margin: 0 13px 24px 13px;display: flex;align-items: center;justify-content: space-between;">
                <img style="width: 15px;height: 15px;" src="../img/46.png" alt="">
                <div style="font-size: 10px;font-weight: 400;color: rgba(50, 100, 237, 1);margin-left: 17px;">
                    30分钟内免费；超过30分钟，每小时收取0.5元，最高上限每天5元；不足1小时，按照一小时收费
                </div>
            </div>

            <div style="margin: 0 13px;display: flex;align-items: center;justify-content: space-between;">
                <van-field
                    style="background: rgba(229, 229, 229, 1);color: rgba(32, 32, 32, 1);border-radius: 5px;height: 50px;font-size: 15px;align-items: center;"
                    v-model="mobile" label="手机尾四位" type="number" placeholder="请输入" />
                <!-- <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    取件人手机尾四号
                </div> -->
                <!-- <div
                    style="width: 1.36rem;height: 0.34rem;opacity: 1;border-radius: 0.05rem;background: rgba(229, 229, 229, 1);font-size: 0.2rem;font-weight: 500;color: rgba(32, 32, 32, 1);line-height: 0.34rem;text-align: center;">
                    3301
                </div> -->
                <!-- <van-field style="width: 1.36rem;height: 0.34rem;opacity: 1;border-radius: 0.05rem;background: rgba(229, 229, 229, 1);color: rgba(32, 32, 32, 1);" v-model="value" placeholder="请输入用户名" /> -->
            </div>


        </div>

        <div
            style="width: 318px;height: 156px;padding: 13px 14px 0 14px;opacity: 1;border-radius: 9px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);margin:0 13px 10px 13px ;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    付款方式
                </div>
                <van-radio-group v-model="checked" direction="horizontal" icon-size="15px" @change="add"
                    style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    <van-radio name="2">我的钱包</van-radio>
                    <van-radio name="1">微信支付</van-radio>
                </van-radio-group>
                <!-- <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    微信支付
                </div> -->
            </div>
            <div
                style="width: 314px;height: 0;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 15px 0;">
            </div>

            <div style="display: flex;justify-content: space-between;align-items: center;">
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    金额
                </div>
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    每次 <span style="color: rgba(50, 100, 237, 1);font-size: 14px;">{{ get_accessible_box_obj.price
                    }}元</span>
                </div>
            </div>
            <!-- <div
                style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-top: 0.09rem;text-align:right;">
                免费次数还剩 <span style="font-size: 0.14rem;font-weight: 400;color: rgba(50, 100, 237, 1);">3次</span>
            </div> -->
            <div
                style="width: 314px;height: 0;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 15px 0;">
            </div>
            <!-- <div style="display: flex;justify-content: space-between;align-items: center;">
                <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    钱包余额
                </div>
                <div style="font-size: 0.14rem;font-weight: 500;color: rgba(32, 32, 32, 1);">
                    ￥62.75
                </div>
            </div> -->


        </div>

        <div
            style="width: 318px;height: 133px;padding: 10px 14px 0 14px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);margin: 0 15px 18px 15px;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.8);">
                    自动扣费通知
                </div>
                <van-checkbox v-model="checked2" icon-size="15px" @change="changeann"
                    style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.8);">已阅读</van-checkbox>
            </div>

            <div
                style="width: 318px;height: 0;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 11px 0">
            </div>
            <div style="font-size: 10px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                1、先联系存柜人员将物品取出，超时的费用由存柜人员支付；
                2、如果存柜人员不处理，平台介入，工作人员给他取出（暂存办公室）；超时费用直接从存柜人员的账户余额中扣除；
                3、如果账户余额不足，做一笔历史记录，当存柜人员，下次存柜还是取柜，必须要先支付之前的超时费用；
            </div>

        </div>

        <van-button type="primary" @click="create_order"
            style="width: 312px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.34);margin: 18px 31px 0 31px;font-size: 16px;font-weight: 500;color: rgba(255, 255, 255, 1);">
            开箱
        </van-button>










    </div>
</template>

<style scoped>
.black {
    width: 100px;
    height: 77px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(32, 32, 32, 0.1);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    font-size: 16px;
    font-weight: 500;
    color: rgba(32, 32, 32, 0.6);
    text-align: center;
    overflow: hidden;
}

.bule {
    width: 100px;
    height: 77px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(50, 100, 237, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0 -3px 0 rgba(0, 0, 0, 0.2);
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    overflow: hidden;
}

/* 文本省略 */
.add {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.van-cell-group--inset {
    margin: 0;
}
</style>


<script >
import { get_box, get_accessible_box, create_order, user_info, pay } from "@/api/api";
import { Notify, Dialog } from 'vant';
// import wx from 'weixin-js-sdk'
export default {
    name: 'Lockers',
    data() {
        return {
            value: '',
            checked: '0',
            checked2: '',

            ann: "",//阅读是否打勾
            mobile: '',//手机号后四位

            get_box_list: [],//柜子大小列表
            get_box_num: -1,//柜子大小列表

            device_id: '',//设备id
            box_type: '',//盒子类型
            user_id: '',//用户id
            role_type: '',//角色类型

            get_accessible_box_obj: {},//所选柜子信息

            user_info_obj: {},//用户信息

            pay_recharge_obj: {},


        }

    },
    mounted() {
        var azz = localStorage.getItem('device_id');
        azz = azz.split('=')[1]
        this.device_id = azz
        console.log(this.device_id);


        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        // this.role_type = use.role_type
        this.user_id = use

        console.log(use);
        this.get_box()
        this.user_info()
    },
    methods: {
        // 获取柜子大小列表
        get_box() {
            get_box().then(res => {
                if (res.data.code == 200) {
                    this.get_box_list = res.data.data
                }
                console.log(res);
            })

        },
        // 点击换箱子颜色
        get_box_on(item, index) {
            console.log(item);
            this.box_type = item.value
            this.get_box_num = index
            this.get_accessible_box()
        },

        // 03-1选柜-所选柜子信息
        get_accessible_box() {
            var data = {
                device_id: this.device_id,
                box_type: this.box_type,
                user_id: this.user_id,
                role_type: this.role_type
            }
            get_accessible_box(data).then(res => {
                if (res.data.code == 200) {
                    this.get_accessible_box_obj = res.data.data
                }
                console.log(res);
            })
        },
        // 用户协议
        changeann(e) {
            console.log(e);
            this.ann = e
        },


        // 01-1用户详情
        user_info() {
            user_info(this.user_id).then(res => {
                if (res.data.code == 200) {
                    console.log(res);
                    this.user_info_obj = res.data.data
                }
            })
        },

        // 03-创建订单支付
        create_order() {
            console.log(this.get_accessible_box_obj);
            if (this.mobile == '') {
                Notify({ type: 'primary', message: '手机后四位没有填写' });
            }
            if (this.ann != true) {
                Notify({ type: 'primary', message: '请阅读规则' });
            }
            if (this.get_accessible_box_obj.price == '') {
                Notify({ type: 'primary', message: '请选择柜子类型' });
            }

            var data = {
                device_id: this.device_id,
                goods_id: this.get_accessible_box_obj.goods_id,
                user_id: this.user_id,
                pay_type: this.checked,
                price: this.get_accessible_box_obj.price,
                // price: 1,
                mobile_short: this.mobile,
                box_id: this.get_accessible_box_obj.box_id,
                // box_type: '1',
                openid: localStorage.getItem('openid')


            }


            create_order(data).then(res => {
                if (res.data.code == 200) {
                    if (this.checked == 2) {
                        if (res.data.data.notify_pick_exist == 2) {
                            Dialog.alert({
                                message: '没有关注公众号，需要电话通知。格箱编号：' + res.data.data.row_col,
                            }).then(() => {
                                // on close
                                this.$router.push('/home')
                            });
                        } else {
                            Dialog.alert({
                                message: '格箱编号：' + res.data.data.row_col,
                            }).then(() => {
                                // on close
                                this.$router.push('/home')
                            });
                        }
                    } else {
                        this.pay(res.data.data.order_sn)
                    }

                } else {
                    Dialog.alert({
                        message: '没有可用格箱',
                    }).then(() => {
                        // on close
                    });
                }
                console.log(res);
            })
        },




        pay(order_sn) {
            var data = {
                order_sn: order_sn,
                openid: localStorage.getItem('openid'),
                device_id: this.device_id,
            }
            pay(data).then(res => {
                if (res.data.code == 200) {
                    console.log(res);
                    this.pay_recharge_obj = res.data.data
                    this.callpay()
                }

            })
        },

        // 单选框的点击
        add(e) {
            console.log(e, this.checked);
        },


        jsApiCall() {
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', this.pay_recharge_obj,
                (res) => {

                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        console.log(res.err_msg);
                        this.$router.push('/recharge')
                    } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                        alert("用户取消支付!");
                    } else {
                        // alert(res.err_msg)
                        alert("支付失败!");
                    }
                }
            );
        },

        callpay() {
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.jsApiCall(), false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.jsApiCall());
                    document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall());
                }
            } else {
                this.jsApiCall();
            }
        }







    }

}


</script>

