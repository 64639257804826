<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="overflow: hidden;width: 346px;height: 301px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0px 3px 12px  rgba(0, 0, 0, 0.09);margin: 99px 15px 19px 15px;">

            <div style="display: flex;align-items: center;margin-top: 19px;margin-left: 11px;">
                <img style="width: 19px;height: 19px;" src="../img/42.png" alt="">
                <div class="add" style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);margin-left: 10px;">
                    苏州大学附属中学(苏大附中)6号柜机
                </div>

            </div>
            <div style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 1);text-align: center;">
                需支付  <span style="font-size: 24px;font-weight: 700;color: rgba(255, 13, 13, 1);">0.5元</span>
            </div>

            <div style="text-align: center;margin: 0px 67px 11px 75px;">
                <img style="width: 100px;height: 95px;" src="../img/47.png" alt="">
            </div>

            <div>
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);text-align: center;">
                    储柜编号：
                </div>
                <div style="font-size: 32px;font-weight: 700;color: rgba(50, 100, 237, 1);text-align: center;">
                    652359
                </div>

            </div>
        </div>

        <div style="width: 328px;height: 194px;padding:18px 9px 0 9px ;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0px 3px 12px  rgba(0, 0, 0, 0.09);margin: 0 15px 37px 15px;">
            <div style="display: flex;align-items: center;justify-content: space-between;">
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    储存时间
                </div>
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    2024-07-18  18:28:23
                </div>

            </div>
            <div style="width: 328px;height: 0px;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 14px 0;"></div>

            <div style="display: flex;align-items: center;justify-content: space-between;">
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    箱类型
                </div>
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    中箱
                </div>

            </div>
            <div style="width: 328px;height: 0px;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 14px 0;"></div>

            
            <div style="display: flex;align-items: center;justify-content: space-between;">
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    付款方式
                </div>
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                    账户余额
                </div>

            </div>
            <div style="width: 328px;height: 0px;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 14px 0;"></div>
            <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);text-align: right;">
                费用 <span style="font-size: 24px;font-weight: 700;color: rgba(255, 0, 0, 1);">￥0.5元</span>
            </div>

        </div>

        <van-button @click="pick_up_pay" type="default" style="color: rgba(255, 255, 255, 1);width: 312px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);box-shadow: 0px 2px 9px  rgba(50, 100, 237, 0.09);margin: 0 31px 12px 32px;">
            支付
        </van-button>











    </div>
</template>

<style scoped>
.black {
    width: 78px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(32, 32, 32, 0.6);
    line-height: 40px;
    text-align: center;
}

.bule {
    width: 78px;
    height: 40px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(50, 100, 237, 1);
    box-shadow: 0px 2px 9px rgba(50, 100, 237, 0.34);
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

/* 文本省略 */
.add {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
export default {
    name: 'PickUpdDetail',
    data() {
        return {
            value: ''
        }

    },
    mounted() {
        // this.add()
    },
    methods: {
        pick_up_pay() {
			this.$router.push('/pick_up_pay')
		},
    }

}


</script>

