<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="width: 375px;height: 261px;opacity: 1;background: url(http://dms.selfbao.com/h5/sxkmg/32.png);overflow: hidden;">
            <div
                style="display: flex;align-items: center;justify-content: space-between;margin: 40px 22px 0px 22px;">
                <div style="font-size: 36px;font-weight: 700;color: rgba(255, 255, 255, 1);">
                    ￥{{ balance }}
                </div>

                <van-button type="default" @click="recharge"
                    style="width: 72px;height: 29px;opacity: 1;border-radius: 78px;background: rgba(255, 255, 255, 1);color: rgba(32, 32, 32, 1);font-size: 15px;">
                    充值
                </van-button>

            </div>

            <div style="margin-top: 5px;margin-left: 31px;display:flex;align-items: center;" @click="application()">
                <div style="font-size: 12px;font-weight: 500;color: rgba(255, 255, 255, 1);">
                    申请退款
                </div>
                <img style="width: 12px;height: 9px;margin-left: 4px;" src="../img/33.png" alt="">

            </div>
        </div>

        <div style="position: absolute;left: 15px;top: 120px;">

            <div
                style="position: relative; width: 345px;height: 511px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0px 1px 4px  rgba(0, 0, 0, 0.16);margin-top: 11px;overflow: hidden;overflow-y: auto;">
                <div style="position: fixed;z-index: 999;background-color: white; width: 345px;">
                    <div
                        style="font-size: 18px;font-weight: 500;color: rgba(32, 32, 32, 1);margin-top: 20px;margin-left: 28px;">
                        账户明细
                    </div>
                    <div style="display: flex;margin-top: 18px;margin-left: 28px;margin-bottom: 0.1;">
                        <div v-for="(item, index) in type" :key="index" @click="type_on(item, index)"
                            :class="{ black: type_index == index, bule: type_index != index }">
                            {{ item }}
                        </div>
                    </div>
                </div>


                <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了"
                    @load="account_record_list_load()" style="margin-top: 90px;">
                    <van-cell v-for="(item, index) in list" :key="index">

                        <div style="margin: 0 27px;" v-if="item.type_name == '消费'">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);">
                                    {{ item.type_name }}
                                </div>
                                <div
                                    style="font-size: 16px;font-weight: 400;letter-spacing: 0px;line-height: 19px;color: rgba(255, 13, 13, 1);">
                                    -￥{{ item.price }}
                                </div>

                            </div>
                            <div
                                style="font-size: 11px;font-weight: 400;color: rgba(32, 32, 32, 0.4);margin-top: 5px;">
                                {{ item.create_at }}
                            </div>
                            <!-- <div
                                style="width: 2.9rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(247, 247, 247, 1);margin-top: 0.09rem;">
                            </div> -->

                        </div>

                        <div style="margin: 0 27px;margin-top: 10px;" v-if="item.type_name == '退款'">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);">
                                    {{ item.type_name }}
                                </div>
                                <div
                                    style="font-size: 16px;font-weight: 400;letter-spacing: 0px;line-height: 19px;color: rgba(255, 141, 26, 1);">
                                    -￥{{ item.price }}
                                </div>

                            </div>
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div
                                    style="font-size: 11px;font-weight: 400;color: rgba(32, 32, 32, 0.4);margin-top: 5px;">
                                    {{ item.create_at }}
                                </div>
                                <div style="display: flex;align-items: center;" @click="progress(item)" v-if="item.refund_status!=2">
                                    <div style="font-size: 12px;font-weight: 400;color: rgba(0, 191, 113, 1);">
                                        查看进度
                                    </div>
                                    <img style="width: 5px;height: 8px;margin-left: 10px;" src="../img/36.png"
                                        alt="">
                                </div>


                            </div>

                            <!-- <div
                                style="width: 2.9rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(247, 247, 247, 1);margin-top: 0.09rem;">
                            </div> -->

                        </div>

                        <div style="margin: 0 27px;margin-top: 10px;" v-if="item.type_name == '充值'">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);">
                                    {{ item.type_name }}
                                </div>
                                <div
                                    style="font-size: 16px;font-weight: 400;letter-spacing: 0px;line-height: 19px;color: rgba(78, 123, 222, 1);">
                                    +￥{{ item.price }}
                                </div>

                            </div>
                            <div
                                style="font-size: 11px;font-weight: 400;color: rgba(32, 32, 32, 0.4);margin-top: 5px;">
                                {{ item.create_at }}
                            </div>
                            <!-- <div
                                style="width: 2.9rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(247, 247, 247, 1);margin-top: 0.09rem;">
                            </div> -->

                        </div>

                        <!-- <div style="margin: 0 0.27rem;margin-top: 0.1rem;">
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                                    退款
                                </div>
                                <div
                                    style="font-size: 0.16rem;font-weight: 400;letter-spacing: 0rem;line-height: 0.19rem;color: rgba(255, 141, 26, 1);">
                                    -￥600.00
                                </div>

                            </div>
                            <div style="display: flex;justify-content: space-between;align-items: center;">
                                <div
                                    style="font-size: 0.11rem;font-weight: 400;color: rgba(32, 32, 32, 0.4);margin-top: 0.05rem;">
                                    2024-06-07 12:50:20
                                </div>



                            </div>

                            <div
                                style="width: 2.9rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(247, 247, 247, 1);margin-top: 0.09rem;">
                            </div>

                        </div> -->


                    </van-cell>




                </van-list>




                <!-- <div style="margin-top: 0.14rem;">
                    <div style="margin: 0 0.27rem;">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                                支出
                            </div>
                            <div
                                style="font-size: 0.16rem;font-weight: 400;letter-spacing: 0rem;line-height: 0.19rem;color: rgba(255, 13, 13, 1);">
                                -￥2.36
                            </div>

                        </div>
                        <div style="font-size: 0.11rem;font-weight: 400;color: rgba(32, 32, 32, 0.4);margin-top: 0.05rem;">
                            2024-06-07 12:50:20
                        </div>
                        <div
                            style="width: 2.9rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(247, 247, 247, 1);margin-top: 0.09rem;">
                        </div>

                    </div>

                    <div style="margin: 0 0.27rem;margin-top: 0.1rem;">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                                退款
                            </div>
                            <div
                                style="font-size: 0.16rem;font-weight: 400;letter-spacing: 0rem;line-height: 0.19rem;color: rgba(255, 141, 26, 1);">
                                -￥600.00
                            </div>

                        </div>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div
                                style="font-size: 0.11rem;font-weight: 400;color: rgba(32, 32, 32, 0.4);margin-top: 0.05rem;">
                                2024-06-07 12:50:20
                            </div>
                            <div style="display: flex;align-items: center;">
                                <div style="font-size: 0.12rem;font-weight: 400;color: rgba(0, 191, 113, 1);">
                                    查看进度
                                </div>
                                <img style="width: 0.05rem;height: 0.08rem;margin-left: 0.1rem;" src="../img/36.png" alt="">
                            </div>


                        </div>

                        <div
                            style="width: 2.9rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(247, 247, 247, 1);margin-top: 0.09rem;">
                        </div>

                    </div>

                    <div style="margin: 0 0.27rem;margin-top: 0.1rem;">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                                充值
                            </div>
                            <div
                                style="font-size: 0.16rem;font-weight: 400;letter-spacing: 0rem;line-height: 0.19rem;color: rgba(78, 123, 222, 1);">
                                +￥9.00
                            </div>

                        </div>
                        <div style="font-size: 0.11rem;font-weight: 400;color: rgba(32, 32, 32, 0.4);margin-top: 0.05rem;">
                            2024-06-07 12:50:20
                        </div>
                        <div
                            style="width: 2.9rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(247, 247, 247, 1);margin-top: 0.09rem;">
                        </div>

                    </div>

                    <div style="margin: 0 0.27rem;margin-top: 0.1rem;">
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                                退款
                            </div>
                            <div
                                style="font-size: 0.16rem;font-weight: 400;letter-spacing: 0rem;line-height: 0.19rem;color: rgba(255, 141, 26, 1);">
                                -￥600.00
                            </div>

                        </div>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div
                                style="font-size: 0.11rem;font-weight: 400;color: rgba(32, 32, 32, 0.4);margin-top: 0.05rem;">
                                2024-06-07 12:50:20
                            </div>



                        </div>

                        <div
                            style="width: 2.9rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(247, 247, 247, 1);margin-top: 0.09rem;">
                        </div>

                    </div>

                </div> -->

            </div>





        </div>





    </div>
</template>

<style scoped>
.black {
    font-size: 14px;
    font-weight: 500;
    color: rgba(32, 32, 32, 1);
    margin-right: 20px;
}

.bule {
    font-size: 14px;
    font-weight: 400;
    color: rgba(32, 32, 32, 0.6);
    margin-right: 20px;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
import { user_balance, account_record_list } from '@/api/api';
// import { List } from 'vant';
export default {
    name: 'My',
    data() {
        return {
            value: '',
            user_id: '',
            device_id: '',
            type: ['全部', '消费', '充值', '退款记录'],
            type_index: 0,

            balance: "",//金额
            page: 1,//分页
            limit: 10,//分页条数
            list: [],//列表
            total: '',//总条数

            loading: false,
            finished: false,


        }

    },
    mounted() {
        // this.add()

        var azz = localStorage.getItem('device_id');
        azz = azz.split('=')[1]
        this.device_id = azz
        console.log(this.device_id);

        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        this.user_id = use


        this.user_balance_on()
        this.account_record_list_on()

    },
    methods: {

        // 点击变色
        type_on(item, index) {
            this.type_index = index
            console.log(item);
            this.page=1
            this.list=[]
            this.account_record_list_on()
        },

        // 06-账户信息-客户余额
        user_balance_on() {

            user_balance(this.device_id, this.user_id).then(res => {
                if (res.data.code == 200) {
                    console.log(res);
                    this.balance = res.data.data.balance
                }

            })
        },

        // 06-账户信息-消费记录信息
        account_record_list_on() {

            var data = {
                device_id: this.device_id,
                user_id: this.user_id,
                type: this.type_index,
                page: this.page,
                limit: this.limit
            }

            account_record_list(data).then(res => {
                if (res.data.code == 200) {
                    // this.list = res.data.data.list
                    this.list = this.list.concat(res.data.data.list)
                    this.total = res.data.data.pager.total

                    this.loading = false

                    if (res.data.data.list.length < 10) {
                        this.finished = true
                    } else {
                        this.finished = false
                    }
                }
                console.log(res);
            })


        },

        // 触底加载
        account_record_list_load() {
            this.loading = true;
            this.page = this.page + 1
            this.account_record_list_on()

        },




        // onLoad(){
        //     // 异步更新数据
        //     // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        //     setTimeout(() => {
        //         for (let i = 0; i < 10; i++) {
        //             list.value.push(list.value.length + 1);
        //         }

        //         // 加载状态结束
        //         loading.value = false;

        //         // 数据全部加载完成
        //         if (list.value.length >= 40) {
        //             finished.value = true;
        //         }
        //     }, 1000);
        // },


        recharge() {
            this.$router.push('/recharge')
        },
        application() {
            this.$router.push('/application')
        },
        progress(item) {
            console.log(item.refund_apply_id);
            this.$router.push({path: '/progress',query:{ refund_apply_id:'1'}});
        },
    }

}


</script>

