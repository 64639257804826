<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="width: 345px;height: 269px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 12px  rgba(0, 0, 0, 0.09);margin: 10px 15px 0 15px;overflow: hidden;">

            <div style="margin-top: 10px;margin-bottom: 10px;border-bottom: 1px solid rgba(32, 32, 32, 0.1);">
                <van-field v-model="refund_amount" type="number" label="退款金额：" />
            </div>
            <div style="margin-top: 10px;margin-bottom: 10px;border-bottom: 1px solid rgba(32, 32, 32, 0.1);">
                <van-field v-model="remark" rows="2" autosize label="备注：" type="textarea" maxlength="150"
                    placeholder="请输入备注" show-word-limit />
            </div>


        </div>

        <div
            style="width: 345px;height: 243px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 12px  rgba(0, 0, 0, 0.09);margin: 10px 15px 0 15px;overflow: hidden;">
            <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);margin: 21px 0 9px 28px;">
                退款说明
            </div>
            <div style="font-size: 10px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin: 0 28px 0 28px;">
                1骑手/其他角色，必须进行充值，确保账户有充足的余额，才能使用存柜功能;存柜过程中，直接扣除账户余额即可，增加交互的流畅度;
            </div>
            <div style="font-size: 10px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin: 0 28px 0 28px;">
                2、用户充值的钱作为账户余额，只能算作钱，每次扣减对应的金额;
            </div>
            <div style="font-size: 10px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin: 0 28px 0 28px;">
                3、用户充值的钱，可以申请退款;剩余钱里需要把赠送的钱扣除，然后在做退款;
            </div>
            <div
                style="width: 290px;height: 52px;opacity: 1;border-radius: 10px;background: rgba(225, 232, 250, 0.3);margin: 15px 28px 0 28px;">
                <span style="font-size: 12px;font-weight: 500;color: rgba(255, 13, 13, 1);">例：</span>
                <span
                    style="font-size: 10px;color: rgba(32, 32, 32, 0.6);">当骑手充值后因故需要退充值余额时，赠送部分不予退还，只退实际金额，即:充1000送200，余800，只退600</span>
            </div>

        </div>
        <van-button type="primary" @click="refund_apply_on()"
            style="width: 312px;height: 48px;border-radius: 10px;box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.34);font-size: 16px;color: rgba(255, 255, 255, 1);margin: 10px 31px 0 31px;">
            提交申请
        </van-button>
        

    </div>
</template>

<style scoped>
.black {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(32, 32, 32, 1);
    text-align: left;
}

.bule {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(50, 100, 237, 1);
    text-align: left;
    vertical-align: top;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
import { refund_apply } from "@/api/api";
import { Notify } from 'vant';
export default {
    name: 'Application',
    data() {
        return {
            refund_amount: '',//提现/退款金额
            user_id: '',
            device_id: '',
            remark:'',//备注内容
            // user_info_obj: {},//用户信息
            // balance: '',
        }

    },
    mounted() {
        // this.add()
        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        // this.role_type = use.role_type
        this.user_id = use

        var azz = localStorage.getItem('device_id');
        azz = azz.split('=')[1]
        this.device_id = azz
        console.log(this.device_id);


    },
    methods: {

        // 07-退款-提现申请
        refund_apply_on(){
            if (this.refund_amount=='') {
                Notify({ type: 'warning', message: '退款金额没有填写' });
                return
            }
            var data={
                user_id:this.user_id,
                device_id:this.device_id,
                refund_amount:this.refund_amount,
                remark:this.remark
            }

            refund_apply(data).then(res=>{
                if (res.data.code==200) {
                    Notify({ type: 'primary', message: '申请成功' });
                    this.$router.push('/purse')
                }else{
                    Notify({ type: 'warning', message: res.data.msg });
                }
                console.log(res);
            })
        },


        // purse() {
        //     this.$router.push('/purse')
        // },
        // orders() {
        //     this.$router.push('/orders')
        // },
    }

}


</script>

