<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div style="text-align: center;margin: 74px 67px 11px 75px;">
            <img style="width: 233px;height: 145px;" src="../img/40.png" alt="">
        </div>
        <div style="display: flex;font-size: 16px;align-items: center;margin-left: 29px;">
            <div style="color: rgba(32, 32, 32, 0.6);">
                计时费用：
            </div>
            <div style="color: rgba(50, 100, 237, 1);">
                0.00元
            </div>
        </div>

        <div style="width: 346px;height: 259px;background-image:url('http://dms.selfbao.com/h5/sxkmg/41.png');background-size: cover;margin: 23px 15px 55px 15px;overflow: hidden;">

            <div style="margin-top: 24px;">
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);text-align: center;">
                    取件码:
                </div>
                <div style="color: rgba(50, 100, 237, 1);font-size: 24px;font-weight: 500;text-align: center;">
                    562526
                </div>
            </div>
            <div style="width: 310px;height: 0;opacity: 1;border: 1px dashed rgba(229, 229, 229, 1);margin: 27px 18px 38px 18px;"></div>

            <div style="margin: 0 9px;">
                <div style="display: flex;justify-content: space-between;">
                    <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                        储存时间
                    </div>
                    <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                        2024-07-18  18:28:23
                    </div>
                </div>

                <div style="display: flex;justify-content: space-between;margin-top:19px ;">
                    <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                        箱类型
                    </div>
                    <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);">
                        中箱
                    </div>
                </div>

            </div>


        </div>

        <van-button type="primary" style="width: 312px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.09);margin: 0 31px 12px 32px;">
            提醒取货
        </van-button>

        <van-button type="default" style="color: rgba(32, 32, 32, 0.6);width: 312px;height: 48px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.09);margin: 0 31px 12px 32px;">
            返回首页
        </van-button>

        






    </div>
</template>

<style scoped>
.black {
    width: 78px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(32, 32, 32, 0.6);
    line-height: 40px;
    text-align: center;
}

.bule {
    width: 78px;
    height: 40px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(50, 100, 237, 1);
    box-shadow: 0 2px 9px rgba(50, 100, 237, 0.34);
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
export default {
    name: 'Certificates',
    data() {
        return {
            value: ''
        }

    },
    mounted() {
        // this.add()
    },
    methods: {
        add() {
            // let aaa = 111


        }
    }

}


</script>

