<template>
    <div
        style="background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);height:100vh;overflow: hidden;padding: 0 15px;overflow-y: auto;">
        <div style="text-align: center;">
            共享寄存柜用户服务协议
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            共享寄存柜服务（以下简称云柜服务）为青岛和群文化传播有限公司（为甲方，以下简称“本公司”）提供的暂存和自助领取的便民服务，使用“共享寄存柜服务”的用户（以下简称“您”）为乙方，经甲乙双方在平等自愿及诚实信用原则的基础上，达成一致，签订以下协议：
        </div>
        <div style="font-size: 20px;">
            一、声明与承诺
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            1．您确认，在使用共享寄存柜服务前，已充分阅读、理解和接受《共享寄存柜服务协议》（“本协议”）所有内容；一旦您使用共享寄存柜，即表示您接受本协议的所有条款。您接受本协议时具有完全民事行为能力。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            2.您同意，本公司有权随时对本协议内容进行单方面的变更，并于共享寄存柜使用终端提示，但无需另行单独通知您，您可以拨打本公司客服热线18822556801咨询；若您在本协议内容变更后继续使用共享寄存柜服务，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用共享寄存柜服务；若您不同意修改后的协议内容，您应停止使用共享寄存柜服务。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            3．您同意，本公司有权从共享寄存柜获取您的相关信息（包括但不限于账户相关信息、物流信息、行为信息等）。共享寄存柜所接收的存取指令完全来自于您的授权，您应确保本公司不因执行您的指令而遭受索赔或损失，且不因执行您的指令而对您可能产生的损失承担赔偿责任。但您确认，您使用共享寄存柜服务时，您仍应完全遵守本协议及共享寄存柜相关的各项规则及页面提示等。
        </div>
        <div style="font-size: 20px;">
            二、定义及解释
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            1．共享寄存柜账号：是本公司向您提供的唯一账号。您可自行为该共享寄存柜账户设置密码等，并用以查询或提醒等。您需使用本人手机号码作为登录手段登录该共享寄存柜账户。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            2．本移动端（或本客户端）：除本协议另有规定外，指共享寄存柜相关移动客户端应用程序及微信服务号、微信小程序。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            3．共享寄存柜派件：指配送员经收件客户本人同意后，将货件派入共享寄存柜的派件服务。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            4．共享寄存柜收寄件：指投递人自行进行自助投递后，收件人在共享寄存柜上进行的收件服务。您应保护好您的共享寄存柜账号等相关信息，否则为此给您遭受的损失由您承担。
        </div>
        <div style="font-size: 20px;">
            三、共享寄存柜服务
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            共享寄存柜服务（以下简称为本服务）：指以下所列的派件、认证、充值付费和查询等服务，以及您实际使用的本公司，或本公司接受您的委托，为您提供的服务。本服务包括但不限于以下服务：
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            1．派件服务：派件服务是指本公司为您提供的自助派件服务。具体是指在符合本公司规定的情况下，您根据本协议条款与客户沟通，经客户同意后将快件存放于共享寄存柜柜内。快件在共享寄存柜柜内未逾期的存放期间，共享寄存柜有责任保障快件安全，避免发生物理损毁、遗失情况。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            2．认证：本公司将对您注册提交的账户信息进行审核认证，对不符合认证要求的账户，共享寄存柜不予开通使用权限，您需要重新提交认证信息。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            3．充值付费：使用时，您可能需要按照甲方平台公布的收费标准、活动或政策，对账号进行充值或付费使用。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            4．查询：本公司将对您使用共享寄存柜的操作进行记录，不论该操作之目的最终是否实现。您可以登录共享寄存柜账户查询您共享寄存柜账户内的交易记录。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            5．申请退款：申请充值余额退款时，只退余额本金不退赠送款（例：当充值1000元送500元，使用800元后，退且仅退200元），充值180天后的申请退款，由平台审核，线下进行退款。
        </div>
        <div style="font-size: 20px;">
            四、共享寄存柜账户相关权责
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            1．除本协议另有规定或相关产品另有规定外，您须在共享寄存柜相关服务端注册，并取得本公司提供给您的共享寄存柜账户，并且按照本公司要求提供相关信息，完成认证后方可使用共享寄存柜相关服务。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            2．按照共享寄存柜要求准确提供并在取得该账号后及时更新正确、最新及完整的身份信息及相关资料。您应保证您提供您的资料及信息真实及准确性，否则因此给您及公司遭受的一切损失和责任由您承担。若本公司有合理理由怀疑您提供的身份信息及相关资料错误、不实、过时或不完整的，本公司有权暂停或终止向您提供部分或全部共享寄存柜服务，本公司对此不承担任何责任。若因国家法律法规、部门规章或监管机构的要求，本公司需要您补充提供任何相关资料时，如您不能及时配合提供，本公司有权暂停或终止向您提供部分或全部共享寄存柜服务。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            3．您有义务保持您提供的联系方式的有效性，如有变更需要更新的，您应按本公司的要求进行操作。您应完全独自承担因通过这些联系方式无法与您取得联系，而导致的您在使用共享寄存柜服务过程中遭受的任何损失，或增加任何费用等不利后果。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            4．本公司有权要求核对您的有效身份证件或其他必要文件，并留存有效身份证件的照片，您有义务并应积极配合，否则本公司有权限制或停止向您提供部分或全部共享寄存柜服务。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            5．您理解并同意，您在完成共享寄存柜账户注册流程后，您即取得以您手机号为准的共享寄存柜账号。您可以通过您的共享寄存柜账号直接登录任意共享寄存柜客户端，无需重新注册。但如您此次注册使用的手机号是已注册过的，则无法重复注册。为了使您更便捷地使用共享寄存柜移动端的服务，您在此明确且不可撤销地授权，您的账户信息在您注册成功时，已授权本公司披露给所有共享寄存柜客户端并授权共享寄存柜使用。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            6．本公司通过您的共享寄存柜账号和密码识别您的指示，您应当妥善保管您的共享寄存柜账号和密码及身份信息，对于因密码泄露、身份信息泄露所致的损失，由您自行承担。您保证不向其他任何人泄露您的共享寄存柜账号及密码以及身份信息，亦不使用其他任何人的共享寄存柜账号及密码。本公司亦可能通过您使用的其他产品或设备识别您的指示，您应当妥善保管处于您或应当处于您掌控下的这些产品或设备，对于这些产品或设备遗失所致的任何损失，由您自行承担。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            7．基于手机端以及使用其他电子设备的用户使用习惯，我们可能在您使用具体产品时设置不同的账户登录模式及采取不同的措施识别您的身份。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            8．您同意：（1）如您发现有他人冒用或盗用您的共享寄存柜登录账号及密码或任何其他未经合法授权之情形，或发生与共享寄存柜账户关联的手机或其他设备遗失或其他可能危及到共享寄存柜账户安全情形时，应立即以有效方式通知本公司，向本公司申请暂停相关服务，以保障您的合法权益； （2）确保您在持续登录任一共享寄存柜客户端时段结束时，以正确步骤离开客户端。本公司不能也不会对因您未能遵守本款约定而发生的任何损失、损毁及其他不利后果负责。您理解本公司对您的请求采取行动需要合理期限，在此之前，本公司对已执行的指令及（或）所导致的您的损失不承担任何责任。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            9．您同意，基于运行和交易安全的需要，本公司可以暂时停止提供或者限制本服务部分功能，或提供新的功能，在任何功能减少、增加或者变化时，只要您仍然使用本服务，表示您已同意本协议或者变更后的协议。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            10．本公司有权了解您使用本公司产品或服务的真实交易背景及目的，您应如实提供本公司所需的真实、全面、准确的信息；如果本公司有合理理由怀疑您提供虚假交易信息的，本公司有权暂时或永久限制您所使用的产品或服务的部分或全部功能。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            11您同意，本公司有权依据国家法律或行政法规所赋予权力的有关机关的要求对您的个人信息以及在共享寄存柜的账户等进行查询、冻结。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            12．您确认，您应自行对您的共享寄存柜账户负责，只有您本人方可使用该账户。该账户不可转让、不可赠与，不可让他人使用。在您决定不再使用该账户时，您应按本公司规定流程向本公司申请注销该账户。您可以通过人工的方式申请注销共享寄存柜账户。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            13．共享寄存柜账户注销将导致本公司终止为您提供共享寄存柜服务，本协议约定的双方的权利义务终止（依本协议其他条款另行约定不得终止的或依其性质不能终止的除外），同时还可能对于该账户产生如下结果：任何兑换代码（现金券、积分券、或优惠券等任何与共享寄存柜账户相关的卡券等）都将作废；如您不愿将该部分卡券消耗掉或将其作废，则您不能申请注销共享寄存柜账户。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            14．您申请注销的共享寄存柜账户应当处于正常状态，即您的共享寄存柜账户的账户信息和用户信息是最新、完整、正确的，且该账户可以使用所有共享寄存柜服务功能的状态。账户信息或用户信息过时、缺失、不正确的账户或被暂停或终止提供服务的账户不能被申请注销。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            15．您申请注销的共享寄存柜账户应当不存在任何由于该账户被注销而导致的未了结的合同关系与其他基于该账户的存在而产生或维持的权利义务，及本公司认为注销该账户会由此产生未了结的权利义务而产生纠纷的情况。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            16．您理解并同意，如(a)您连续6个月未使用您的共享寄存柜账户或未通过本公司认可的其他方式登录过，本公司有权注销您名下的共享寄存柜账户，您将不能再通过该登录名登录或使用共享寄存柜，并且账户余额不予退还；或(b)您在任一共享寄存柜客户端有欺诈、发布虚假消息、侵犯他人合法权益或其他严重违反任一共享寄存柜规则的行为的，本公司有权根据具体情况：暂停您的帐号，一并暂时冻结帐号中的余额或注销您名下的共享寄存柜账户，您将不能再登录任一共享寄存柜客户端，所有共享寄存柜的服务将同时终止，且账户余额不予退还
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            17．您（包括但不仅限于配送员/投递人和收件人）同意，您将快件投入共享寄存柜之后，甲方有权对快件用户通知催领；甲方为用户提供寄存保管，且甲方有权收取适当存放费用，相关收费标准以在公共渠道公布为准；从投递入柜之时算起，超过12小时未取走的快件（时长或根据季节、区域气候等因素做适当调整，如有调整，将由共享寄存柜提前公布公示），将被视为逾期件，甲方将予以自行处理，且不必再行通知乙方和寄存用户，由此导致用户或乙方产生的费用及损失本公司概不承担。
        </div>
        <div style="font-size: 20px;">
            五、共享寄存柜服务使用限制
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            1．共享寄存柜所提供的服务范围仅限于快件的自助投递和自助领取，该服务不应作为投递快件的任何转让、交付、占有等物权行为及其凭证或证据，投递人和收件人在完全清楚服务范围的情况下自愿使用共享寄存柜。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            2．投递人需先征求收件人同意后，方能使用共享寄存柜。如投递人在未获得收件人授权允许的情况下，将快件存入共享寄存柜，而收件人又不愿使用共享寄存柜提取快件的，应由投递人和收件人自行处理，由此产生的收件人或投递人的费用及损失本公司概不承担。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            3．投递人将快件投入共享寄存柜，即默认为取得了取件人的同意，由此产生的纠纷，本公司概不负责。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            4．收件人使用共享寄存柜所发短信、微信的提取密码开箱，即视为收件人同意投件人员使用共享寄存柜，且自愿使用共享寄存柜，愿遵守本协议，接受共享寄存柜的使用条件。收件人应妥善保管好收到的取件开箱密码，不得泄露给其他人，收件人应及时的取出投递件。由于收件人泄露密码导致快件丢失、损坏或出现快件安全问题和责任的，本公司概不负责。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            5．禁止在共享寄存柜投递我国邮政及法律法规禁止流通或寄存的物品。我国法律法规禁止流通或者寄递的物品包括但不限于武器、弹药、麻醉药物、生化制品、传染性物品和爆炸性、易燃性、腐蚀性、放射性、毒性等危险物品及含有反动、淫秽或有伤风化内容的报刊书籍、图片、宣传品、音像制品，激光视盘(VCD、DVD、LD)、计算机磁盘及光盘等。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            6．以下情形的快件，不被允许投递到共享寄存柜中（否则产生的一切损失及后果由您承担）：（1）易变质、易腐烂等不适合在云柜的存储条件下存放的快件，超出共享寄存柜容纳体积的快件；（2）电话号码及地址填写不清楚的快件；（3）货到付款或者到付外卖费的外卖；（4）收件人明确要求本人签收的快件；（5）其他不适合使用投递共享寄存柜的快件。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            7．关于投递到共享寄存柜中的快件包装是否破损，快件是否安全，是否腐败、变质以及其它不符合食品安全的情况，全部责任由承运该快件的订件平台或投递公司及投递该快件的投递员负责，由此产生的一切责任与本公司无关。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            8．共享寄存柜采用即时短信、微信等方式通知收件人，由于投递人输错手机号码，手机停机，或者短信、微信延迟、丢失或其他情形导致收件人未及时收取快件且造成损失的，由过错方承担赔偿责任，本公司不承担赔偿责任。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            9．投递人投递时，请仔细核查收件人手机号码的正确性，由于收件人手机号码错误，导致收件人无法收件或者外卖丢件的，因此造成的损失由投递人承担；本公司不承担赔偿责任。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            10．因不可抗力（“不可抗力”指不能预见、不能避免和不能克服的客观情况，包括但不限于政府行为、主管部门政策变化、战争、地震、台风、洪水、火灾等其他类似事件）造成快件损伤的，本公司不承担赔偿责任。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            11．如无法正常打开共享寄存柜箱子投件/取件，请及时和共享寄存柜客服电话/微信18822556801联系，未经书面授权，严禁任何人采取任何方式打开共享寄存柜，盗取他人财物、信息或者恶意损坏共享寄存柜设备，否则自行自愿承担因此造成的一切民事责任和刑事责任。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            12．投递人和收件人须严格按照本协议的约定使用共享寄存柜，因为投递人和收件人自己原因造成的损失，本公司不承担赔偿责任。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            13．本公司系统因下列状况无法正常运作，使您无法使用各项服务时，本公司不承担损害赔偿责任，该状况包括但不限于以下内容：（1）本公司在系统停机维护期间；（2）电信设备出现故障不能进行数据传输的；（3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的；（4）由于黑客攻击、电信部门技术调整或故障、网站升级等原因而造成的服务中断或者延迟；（5）因非公司原因导致的停电而无法使用的。
        </div>
        <div style="font-size: 20px;">
            六、隐私权保护
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            本公司重视对用户隐私的保护。关于您的身份资料和其他特定资料依本协议受到保护与规范。
        </div>
        <div style="font-size: 20px;">
            七、责任范围及责任限制
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            1．本公司仅对本协议中列明的责任承担范围负责。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            2．您明确因交易所产生的任何风险应由您与交易对方承担。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            3．共享寄存柜用户信息是由用户本人自行提供的，本公司无法保证该信息之准确、及时和完整，您应对您的判断承担全部责任。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            4．本公司不对交易及本服务提供任何形式的保证，包括但不限于以下事项：（1）本服务符合您的需求；（2）本服务不受干扰、及时提供或免于出错；
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            5．本服务之合作单位，所提供之服务品质及内容由该合作单位自行负负责。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            6．您在使用共享寄存柜服务过程中，所有收派件操作需遵照贵公司标准进行，如因不规范操作发生的投诉类问题，共享寄存柜不承担相应责任。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            7．您自本公司及本公司工作人员或经由本服务取得之建议和资讯，无论其为书面或口头形式，均不构成本公司对本服务之保证。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            8．在法律允许的情况下，本公司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括但不限于业务损失、收益损失、利润损失、商誉损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            9．您充分知晓并同意本公司可能同时为您及您的（交易）对手方提供本服务，您同意对本公司可能存在的该等行为过程中的过错责任予以明确豁免，并不得以此来主张本公司在提供本服务时存在法律上的瑕疵。
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            11．本协议经双方确认自愿达成一致，后续甲方根据共享寄存柜自身业务及服务需要、国家法法规及规章制定的变化，可甲方自行修改本协议，乙方对此均同意，并对双方自有约束力。
        </div>
        <div style="font-size: 20px;">
            八、争议解决
        </div>
        <div style="font-size: 12px;text-indent:20px;">
            如就本协议的解释或履行的事宜发生争议，若无法通过协商解决，如协商不成，双方一致同意将纠纷提交甲方所在地有管辖权的人民法院裁判。
        </div>












    </div>
</template>

<style scoped>
.black {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height:25px;
    color: rgba(32, 32, 32, 1);
    text-align: left;
}

.bule {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 25px;
    color: rgba(50, 100, 237, 1);
    text-align: left;
    vertical-align: top;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >

export default {
    name: 'Privacy',
    data() {
        return {

        }

    },
    created() {
    },
    mounted() {


    },
    methods: {




    }

}


</script>
