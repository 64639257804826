import http from '../disposition/request'

// 00-设备详情
export function device_info(device_id){
    return http({
        url:'device_info?device_id='+device_id,
        method:'get',
    })
}

// 01-1用户详情
export function user_info(user_id){
    return http({
        url:'user_info?user_id='+user_id,
        method:'get',
    })
}

// 获取openid
export function get_wechat_code(code){
    return http({
        url:'get_wechat_code?code='+code,
        method:'get',
    })
}

// 02-1注册选项枚举-角色列表
export function get_role(data){
    return http({
        url:'get_role',
        method:'get',
        data
    })
}

// 02-1注册-用户注册
export function register(nickname,mobile,password,role_type,wechat_openid){
    return http({
        url:'register?nickname='+nickname+'&mobile='+mobile+'&password='+password+'&role_type='+role_type+'&wechat_openid='+wechat_openid,
        method:'get',
        
    })
}

// 02登录-手机验证码登录
export function login_code(data){
    return http({
        url:'login_code',
        method:'post',
        data
    })
}

// 02登录-获取手机验证码
export function send_code(mobile){
    return http({
        url:'send_code?mobile='+mobile,
        method:'get',
        
    })
}

// 02登录-手机号密码登录
export function login_password(data){
    return http({
        url:'login_password',
        method:'post',
        data
    })
}

// 03存柜栏目-存柜选项
export function get_box(){
    return http({
        url:'get_box',
        method:'get',
        
    })
}

// 03-1选柜-所选柜子信息
export function get_accessible_box(data){
    return http({
        url:'get_accessible_box',
        method:'post',
        data
    })
}

// 03-创建订单支付
export function create_order(data){
    return http({
        url:'create_order',
        method:'post',
        data
    })
}

// 07-订单列表
export function order_list(user_id,device_id){
    return http({
        url:'order_list?user_id='+user_id+'&device_id='+device_id,
        method:'get',
        
    })
}

// 03-创建支付
export function pay(data){
    return http({
        url:'pay',
        method:'post',
        data
    })
}

// 04-取件操作
export function get_box_info(order_id){
    return http({
        url:'get_box_info?order_id='+order_id,
        method:'get',
        
    })
}

// 04-取件-取件码取件
export function pickup_code(device_id,pickup_code){
    return http({
        url:'pickup_code?device_id='+device_id+'&pickup_code='+pickup_code,
        method:'get',
        
    })
}

// 04订单-全部
export function all_order_list(data){
    return http({
        url:'all_order_list',
        method:'post',
        data
    })
}

// 04-订单列表提醒取件
export function notify_by_order(device_id,order_id){
    return http({
        url:'notify_by_order?device_id='+device_id+'&order_id='+order_id,
        method:'get',
        
    })
}

// 充值套餐列表
export function get_recharge(device_id){
    return http({
        url:'get_recharge?device_id='+device_id,
        method:'get',
        
    })
}

// 05-充值支付
export function pay_recharge(data){
    return http({
        url:'pay_recharge',
        method:'post',
        data
    })
}

// 06-账户信息-客户余额
export function user_balance(device_id,user_id){
    return http({
        url:'user_balance?device_id='+device_id+'&user_id='+user_id,
        method:'get',
        
    })
}

// 06-账户信息-消费记录信息
export function account_record_list(data){
    return http({
        url:'account_record_list',
        method:'post',
        data
    })
}

// 07-退款-提现申请
export function refund_apply(data){
    return http({
        url:'refund_apply',
        method:'post',
        data
    })
}

// 07-退款-获取详情
export function refund_info(device_id,user_id,refund_apply_id){
    return http({
        url:'refund_info?device_id='+device_id+'&user_id='+user_id+'&refund_apply_id='+refund_apply_id,
        method:'get',
        
    })
}

// 03-支付-取件超时支付
export function pay_pick(data){
    return http({
        url:'pay_pick',
        method:'post',
        data
    })
}

// 9-支付-点金计划回调接口
export function get_reback_info(order_sn){
    return http({
        url:'get_reback_info?order_sn='+order_sn,
        method:'get',
        
    })
}

// 10-公众号操作-设备列表
export function wechat_device_list(){
    return http({
        url:'wechat_device_list?merchant_id=8149',
        method:'get',
        
    })
}

// 10-公众号操作-指定设备下箱格列表
export function wechat_box_list(device_id){
    return http({
        url:'wechat_box_list?device_id='+device_id,
        method:'get',
        
    })
}

// 10-公众号操作-指定开箱
export function wechat_open_box(box_id,local,use,device_id){
    return http({
        url:'wechat_open_box?box_id='+box_id+'&local='+local+'&use='+use+'&device_id='+device_id,
        method:'get',
        
    })
}

// 10-公众号操作-公众号清箱子
export function wechat_clear(local,use,use_status,device_id){
    return http({
        url:'wechat_clear?local='+local+'&use='+use+'&use_status='+use_status+'&device_id='+device_id,
        method:'get',
        
    })
}





