<template>
	<div
		style="background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);height:100vh;overflow: hidden;">
		<div>

			<img style="width: 3.11rem;height: 1.46rem;opacity: 1;position: absolute;left: 0.6rem;top: 0.7rem;"
				src="../img/1.png" alt="">
			<div
				style="position: absolute; left: 0.27rem;top: 1.1rem;width: 0.71rem;height: 0.66rem;opacity: 1;font-size: 0.24rem;font-weight: 500;letter-spacing: 0rem;line-height: 0.33rem;color: rgba(32, 32, 32, 1);text-align: left;vertical-align;">
				HELLO!
			</div>
		</div>

		<div
			style="left: 0.3rem;top: 2.16rem;width: 1.92rem;height: 0.31rem;opacity: 1;display: flex;justify-content: flex-start;align-items: flex-start;position: absolute;justify-content: space-between;">

			<div class="bule">验证码登录</div>
			<div class="black">密码登录</div>

		</div>
		<div style="margin-top: 2.71rem;margin-left: 0.32rem;">
			<div>

				<van-cell-group inset style="width: 3.12rem;">
					<van-field v-model="value" placeholder="手机号" />
				</van-cell-group>
			</div>

			<div style="margin-top: 0.24rem;">

				<van-cell-group inset style="width: 3.12rem;">
					<van-field v-model="sms" center clearable placeholder="验证码">
						<template #button>
							<van-button size="small" type="primary">发送验证码</van-button>
						</template>
					</van-field>
				</van-cell-group>
			</div>

			<div style="margin-top: 0.24rem;">

				<van-cell-group inset style="width: 3.12rem;">
					<van-field v-model="value" placeholder="密码" />
				</van-cell-group>
			</div>

			<van-checkbox v-model="checked" icon-size="15px"
				style="font-size: 0.12rem;margin-top: 0.12rem;">我已阅读用户协议和隐私协议</van-checkbox>
			<!-- <van-radio-group v-model="checked" icon-size="12px" style="font-size: 0.12rem;margin-top: 0.12rem;">
				<van-radio name="1">我已阅读用户协议和隐私协议</van-radio>
			</van-radio-group> -->


		</div>

		<van-button @click="home"
			style="width: 3.12rem;height: 0.48rem;border-radius:0.1rem;margin-left: 0.32rem;margin-top: 0.24rem;"
			type="primary">立即登录</van-button>


		<div style="margin: 1.25rem auto;display: flex;align-items: center;justify-content: center;">
			<div @click="register"
				style="font-size: 0.16rem;font-weight: 400;letter-spacing: 0rem;line-height: 0.22rem;color: rgba(50, 100, 237, 1);text-align: left;vertical-align: top;text-align: center;">
				去注册
			</div>
			<img style="width: 0.16rem;height: 0.16rem;" src="../img/17.png" alt="">


		</div>








	</div>
</template>

<style scoped>
.black {
	font-size: 0.18rem;
	font-weight: 400;
	letter-spacing: 0rem;
	line-height: 0.25rem;
	color: rgba(32, 32, 32, 1);
	text-align: left;
}

.bule {
	font-size: 0.18rem;
	font-weight: 500;
	letter-spacing: 0rem;
	line-height: 0.25rem;
	color: rgba(50, 100, 237, 1);
	text-align: left;
	vertical-align: top;
}

.van-cell-group--inset {
	margin: 0;
}
</style>

<script >
import { get_box,get_wechat_code } from "@/api/api";
// import axios from "axios";
export default {
	name: 'Login_copy',
	data() {
		return {
			value: '',
			checked: 0,
			code: '',
		}

	},
	created(){
		this.getOpenId()
	},
	mounted() {
		alert(window.location.href);
		// this.add()

		// var local = 'https://dmsmpapi.selfbao.com/sxkmg/get_wechat_code'
		// const code = this.getUrlParam('code')
		// if (code == null || code === '') {
		// 	// const local = window.location.href
		// 	window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc21c717d8211eced&redirect_uri=https://dmsmpapi.selfbao.com/sxkmg/get_wechat_code&response_type=code&scope=snsapi_base&state=1#wechat_redirect';
		// } else {
		// 	// this.getOpenId(code) //把code传给后台获取用户信息
		// }

		
	},
	methods: {
		add() {
			// let aaa = 111
			get_box().then(res => {
				console.log(res);
			});

		},

		getUrlParam(name) {
			var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
			let url = window.location.href.split('#')[0]
			let search = url.split('?')[1]
			if (search) {
				var r = search.substr(0).match(reg)
				if (r !== null)
					return unescape(r[2])
				return null
			} else
				return null
		},

		getOpenId() {
			const code = this.getUrlParam('code')  // 截取路径中的code,
			if (code == null || code === '' || code === false) {
				const local = 'http://shuangxiangguih5.selfbao.com/login_copy'
				let appid = 'wxc21c717d8211eced'
				window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect';

			
			} else {
				
				// let that = this;

				get_wechat_code({
					code:code
				}).then(res=>{
					console.log(res);
					alert(11111111111)
				})
			}
		},




		register() {
			this.$router.push('/register')
		},
		home() {
			this.$router.push('/home')
		},

	}

}


</script>
